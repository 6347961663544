/* style.css */
.background-container {
    background-image: url('../../../public/rl_image.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.troll-container {
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.troll-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.troll-text {
    font-size: 18px;
    margin-bottom: 10px;
}
